@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css?family=Roboto+Mono&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  touch-action: none;
  overflow: hidden;
}

.background-canvas {
  touch-events: all;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.App {
  max-width: 600px;
  margin: auto;
  position: relative;
  color: #ffffff;
  padding: 25px;
}

.page-header {
  font-size: 48px;
  line-height: 56px;
  padding-bottom: 25px;
}

.page-header.visible-always {
  z-index: 10;
  pointer-events: none;
  position: absolute;
}

.page-header.visible-always > .the-n {
  color: greenyellow;
}

.description {
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
}

body {
  /* padding-bottom: 25px; */
  background-color: #000000;
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: normal;
  margin: 0;
  height: 100vh;
}

@media (max-width: 500px) {
  body {
    position: fixed;
    width: 100%;
  }
}

.footer {
  padding-top: 25px;
  text-align: right;
  font-size: 14px;
  color: greenyellow;
}

.footer > a,
.footer > a:visited,
.footer > a:link,
.footer > a:focus,
.footer > a:active {
  color: greenyellow;
}

.footer > a:hover {
  text-decoration: none;
  color: black;
}

.main {
  position: relative;
}

@keyframes blinker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.blinker {
  -webkit-animation-name: blinker;
  animation-name: blinker;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: cubic-bezier(0.5, 0, 1, 1);
  animation-timing-function: cubic-bezier(0.5, 0, 1, 1);
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}
